import React from 'react'
import './IconStyles.css';
import { MdOutlineCalendarViewDay } from "react-icons/md";

export const ArrScheduleIcon = (props) => (
    <div style={{minWidth: "1.75em"}}>
        <MdOutlineCalendarViewDay
        size={props.size ? props.size : "1.25em"}
                                                 color={props.color ? props.color : undefined}/>
    </div>
)